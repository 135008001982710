import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';

const Layout = () => {
  return (
    <div>
      <div className='nav-tab-container'>
        <NavLink
            className={({ isActive }) =>
              [
                isActive ? "nav-tab-active" : "nav-tab-inactive",
              ].join(" ")
            }
          to="/"
        >
          Home
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            [
              isActive ? "nav-tab-active" : "nav-tab-inactive",
            ].join(" ")
          }
          to="/privacy"
        >
          Privacy policy
        </NavLink>
      </div>
      <hr />
      <Outlet />
    </div>
  );
}

export default Layout