import React from 'react';
import { DisclaimerText } from '../components/Disclaimer';



const PrivacyPage = () => {

  return (
    <DisclaimerText />
  )
}

export default PrivacyPage;