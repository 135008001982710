import React from 'react';
import './styles/App.css';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import PrivacyPage from './pages/privacy';
import HomePage from './components/Home';
import { Typography } from '@mui/material';



function PlataeaStudios() {



  return (
    <div className="App">
      <header className="banner">
        <Typography variant='h3'>
          Plataea Studios
        </Typography>
      </header>
      <Layout />
      <Routes>
        <Route path="/" element={<HomePage />}/>
        <Route path="/privacy" element={<PrivacyPage />} />
      </Routes>
    </div>
  );
}

export default PlataeaStudios;
