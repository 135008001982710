import { StoreSectionType } from '../types/storeDefinitions'

export const paddleBaddleStoreInfo:StoreSectionType = {
  appName: 'Paddle Baddle',
  productLogo: './images/pb-logo.png',
  description: 'A classic pong style arcade game',
  stores: [
    {
      storeName: 'Apple App Store',
      link: 'https://apps.apple.com/us/app/paddle-baddle/id6444807736',
      qrimage: './images/ios-paddlebaddle.png',
    },
    {
      storeName: 'Google Play',
      link: 'https://play.google.com/store/apps/details?id=com.pjjpb.com.unity.template.mobile2D',
      qrimage: './images/android-paddlebaddle.png',
    },
  ]
};

export const astroVoltsStoreInfo:StoreSectionType = {
  appName: 'AstroVolts',
  productLogo: './images/astrovolts-logo.png',
  description: 'An electric circuit puzzle that will challenge you',
  stores: [
    {
      storeName: 'Apple App Store',
      link: 'https://apps.apple.com/us/app/astrovolts/id6466743456',
      qrimage: './images/ios-astrovolts.png',
    },
    {
      storeName: 'Google Play',
      link: 'https://play.google.com/store/apps/details?id=com.pjjpb.astrovolts.mobile',
      qrimage: './images/android-astrovolts.png',
    }
  ]
};

export const webWhackerStoreInfo:StoreSectionType = {
  appName: 'WebWhacker',
  productLogo: './images/webwhacker-logo.png',
  description: 'A zen internet experience is just a tap away',
  stores: [
    {
      storeName: 'Apple App Store',
      link: 'https://apps.apple.com/us/app/webwhacker/id6587317126',
      qrimage: './images/ios-webwhacker.png',
    }
  ]
};
