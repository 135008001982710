import React from 'react';
import { StoreItemType, StoreSectionProps } from '../types/storeDefinitions';
import { paddleBaddleStoreInfo, astroVoltsStoreInfo, webWhackerStoreInfo } from '../data/stores';
import { Box, Card, Typography } from '@mui/material';


const StoreItem = ({...store}:StoreItemType) => {
  return (
    <Card variant='elevation' className='flex-grid-column flex-grid-item' sx={{backgroundColor: '#282c34', width: '100%', paddingBottom: '6px'}}>
      <Typography variant='h6' className='store-item-name'>{store.storeName}</Typography>
      <a href={store.link}>Link to store page</a>
      <img alt={`qr code for ${store.storeName}`} src={store.qrimage} height={200} className='image-item'/>
    </Card>
  )
}


const ProductSection = ({product}:StoreSectionProps) => {
  return (
    <Box className='flex-grid-item flex-grid-column'>
      <Typography variant='h5' className='store-name'>
        <img alt={`product logo for ${product.appName}`} src={product.productLogo} height={110} className='image-item'/>
        <div>
          <div style={{margin: 'auto', textAlign: 'center'}}>
            {product.appName}
          </div>
          <hr/>
          <Typography variant='body1' style={{padding: '0px 4px 0px 4px', textAlign: 'center'}}>{product.description}</Typography>
        </div>
      </Typography>
        {product.stores.map(store =>
        <StoreItem
          {...store}
        />
        )}
    </Box>
  )
}


const HomePage = () => {

  return (
    <div className='flex-grid'>
      <ProductSection product={paddleBaddleStoreInfo} />
      <ProductSection product={astroVoltsStoreInfo} />
      <ProductSection product={webWhackerStoreInfo} />
    </div>
  )
}

export default HomePage;
